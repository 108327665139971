// Pageable initialization
// new Pageable("#js-cc-pageable-container", {
//   anchors: [home, projects, skills, contact],
//   pips: true,
//   animation: 800,
//   delay: 0,
//   throttle: 50,
//   orientation: "vertical",
//   swipeThreshold: 50,
//   infinite: false,
//   events: {
//     wheel: true,
//     mouse: false,
//     touch: false,
//     keydown: true,
//   },
// });

// Constants
const hamburgerMenu = document.getElementById("js-cc-hamburgerMenu");
const overlayMenu = document.getElementById("js-cc-navigation-overlay");

// Hamburger menu
hamburgerMenu.addEventListener("click", () => {
  hideMenu();
});

function hideMenu() {
  overlayMenu.animate([{ opacity: "1 0" }]);
  overlayMenu.classList.toggle("cc-navigation__container--open");
  if (overlayMenu.classList.contains("cc-navigation__container--open")) {
    hamburgerMenu.innerHTML = '<i class="fa-solid fa-xmark"></i>';
  } else {
    hamburgerMenu.innerHTML = '<i class="fa-solid fa-bars"></i>';
  }
}

// Circle following cursor
if (window.matchMedia("(min-width: 768px)").matches) {
  let mousePosX = 0,
    mousePosY = 0,
    mouseCircle = document.getElementById("mouse-circle");

  document.onmousemove = (e) => {
    mousePosX = e.pageX;
    mousePosY = e.pageY;
  };

  let delay = 6,
    revisedMousePosX = 0,
    revisedMousePosY = 0;

  function delayMouseFollow() {
    requestAnimationFrame(delayMouseFollow);

    revisedMousePosX += (mousePosX - revisedMousePosX) / delay;
    revisedMousePosY += (mousePosY - revisedMousePosY) / delay;

    mouseCircle.style.top = revisedMousePosY + "px";
    mouseCircle.style.left = revisedMousePosX + "px";
  }
  delayMouseFollow();
}

// Form

const fullName = document.getElementById("full-name");
const email = document.getElementById("email-address");
const message = document.getElementById("message");
const form = document.getElementById("contact-form");
const overlay = document.getElementById("cc-contact-form-overlay");

form.addEventListener("submit", function (event) {
  overlay.classList.add("cc-contact-form-overlay--open");
});
